.joinpage {
    text-align: center;
    color: #477dbc;
    
    .container {
        padding: 0 25px;
        .logo {
            width: 100px;
        }
    
        #title {
            font-size: 40px;
            text-transform: uppercase;
        }
        
        #description {
            font-size: 18px;
            margin-bottom: 25px;
        }
    
        .code_input {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 5px;
        }

        #err {
            margin: 0;
            color: #ee3d3d;
        }
    }

    footer {
        position: absolute;
        width: 100%;
        bottom: 0;
        padding-bottom: 25px;
        z-index: -1;
        
        img {
            width: 100%;
        }
        
        p {
            position: absolute;
            width: 100%;
            bottom: 0;
            text-align: center;

            &, a {
                text-decoration: none;
                color: #888;
            }
        }
    }
}