.scoreboard-page .content {
    padding: 0 25px;
    color: #477dbc;
    
    .head-title {
        text-align: center;
        margin: 0 0 50px;

        * { margin: 0; }
        h3 { font-weight: normal; }
    }

    #empty {
        text-align: center;
        color: #555;
    }

    .score {
        color: #333;
        border-bottom: 1px solid #888;
        padding: 10px;

        * { margin: 0; }

        display: flex;
        align-items: center;
        justify-content: space-between;

        .name {
            font-weight: bold;
            font-size: 18px;
        }

        .time {
            opacity: 80%;
        }

        .place {
            font-size: 28px;
            font-weight: bold;
        }

        &.searched {
            background-color: #477dbd;
            color: #fff;
        }
    }
}