.teamname-page {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .content {
        text-align: center;
        padding: 0 15px 25px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 75px;

        color: #477dbc;

        // position: absolute;
        // top: 55px;
        // bottom: 0;
        // left: 0;
        // right: 0;
    
        .head-title {
            font-size: 42px;
            margin: 0;
        }

        .time {
            p {
                margin: 0;
                color: #555;
            }

            h3 {
                font-size: 52px;
                margin: 0;
                color: #222;
            }
        }

        .team-input {
            width: 100%;

            p {
                text-align: start;
                margin: 0;
            }
        }

        .team-leader {
            form {
                display: flex;
                align-items: center;
                gap: 5px;
    
                input { width: 100%; }
            }

            #muted {
                font-size: 12px;
                color: #888;
            }
        }

        .team-view #teamname {
            text-align: center;
            font-size: 22px;
            font-weight: bold;
            padding: 0 10px;
            width: fit-content;
            display: block;
            margin: 25px auto 0;
            border-bottom: 2px solid;
        }
    }

    footer {
        position: absolute;
        width: 100%;
        bottom: 0;
        padding-bottom: 25px;
        text-align: center;
        z-index: -1;
        
        img {
            width: 100%;
        }
        
        p {
            position: absolute;
            width: 100%;
            bottom: 0;

            &, a {
                text-decoration: none;
                color: #888;
            }
        }
    }
}