.websocket-status {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000000aa;
    backdrop-filter: blur(5px);
    color: #eee;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    z-index: 1000;

    &[connected="true"] {
        display: none;
    }

    .container {
        width: 80%;
        max-width: 400px;

        p {
            margin: 0;

            &#title {
                font-weight: bold;
                font-size: 24px;
            }

            &#status {
                font-size: 12px;
                color: #eeeeeeaa;
            }
        }
    }
}