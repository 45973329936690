.gamepage {
    color: #666;

    header {
        .percentage {
            background-color: #81BC58;
            color: #fff;
            font-weight: bold;
            background: linear-gradient(to bottom, #81BC58, #6A9B48);
            width: 60px;
            aspect-ratio: 1;
            border-radius: 50%;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 15px;
            right: 60px;
            box-shadow: 0 2px 5px #00000088;
        }

        margin-bottom: 35px;
    }

    #head-title {
        // max-width: 80%;
        overflow: hidden;
        overflow-wrap: break-word;
        font-size: 18px;
        color: #555;
        padding: 0 130px 0 10px;
        text-overflow: ellipsis;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 55px;
        display: flex;
        align-items: center;
        font-weight: bold;
    }
    
    .content {
        padding: 5px 15px;
        .container {
            margin-top: 25px;
        }
    }

    footer {
        p {
            text-align: center;

            &, a {
                text-decoration: none;
                color: #888;
            }
        }
    }
}
