.language_select {
    position: relative;
    width: fit-content;
    z-index: 1000;
    // margin: 5px 5px 5px auto;

    .flag {
        position: relative;
        width: 35px;
        aspect-ratio: 1/1;
        border-radius: 50%;
        overflow: hidden;

        img {
            position: absolute;
            height: 100%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .current_country {
        padding: 5px;
        background-color: #fff;

        &.focus {
            background-color: #00000022;
        }
    }

    .selector {
        position: absolute;
        top: 100%;
        background-color: #fff;
        border-radius: 0 0 15px 15px;
        padding: 5px;
        max-height: 100px;
        transition: 250ms ease;
        overflow: hidden;
        border-top: 2px solid #aaa;
        box-shadow: 0px 2px 2px #00000088;
        z-index: 100;

        &.hidden {
            max-height: 0;
            opacity: 0;
        }

        .flag:not(:nth-child(1)) {
            margin-top: 5px;
        }
    }

    
}