.image-container {
    width: 100%;
    position: relative;
    background-color: #000;
    aspect-ratio: 1; // 16/9;
    overflow: hidden;

    .history_overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #00000088;
        backdrop-filter: blur(4px);
        color: #fff;
        padding: 10px 15px;
        overflow-x: hidden;
        overflow-y: auto;
        box-shadow: inset 0 0px 15px 0px black;

        h1, p { margin: 0 0 5px; padding: 0; }

        #close {
            font-size: 32px;
            margin: 0 0 15px -5px;
            cursor: pointer;
        }
    }

    .images {
        position: absolute;
        width: 100%;
        height: 100%;

        .image {
            position: absolute;
            width: 100%;
            height: 100%;
            overflow: hidden;
        }

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: 100%;
        }
    }

    .controls {
        #info {
            position: absolute;
            top: 10px;
            left: 10px;
            color: #fff;
            font-size: 32px;
        }

        .arrows {
            position: absolute;
            width: 100%;
            font-size: 32px;
            color: #fff;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            display: flex;
            align-items: center;
            justify-content: space-between;

            #left, #right {
                background-color: #00000088;
                margin: 0 10px;
                border-radius: 10px;
            }
        }

        .dots {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 5px;
            left: 50%;
            bottom: 5px;
            transform: translateX(-50%);
            background-color: #00000088;
            padding: 5px 10px;
            border-radius: 15px;

            .dot {
                width: 8px;
                aspect-ratio: 1;
                background-color: #ffffff;
                border-radius: 50%;

                &:not(.selected) {
                    opacity: 50%;
                }
            }
        }
    }
}