.puzzle-page {
    .hints {
        display: flex;
        align-items: center;
        justify-content: space-between;

        button {
            width: 20%;
            aspect-ratio: 1;
            padding: 2px;
            border-radius: 10px;
        }
    }

    .hint {
        p {
            margin: 10px 10px 5px;
            color: #6A9B48;
        }

        .hint-container {
            position: relative;
            overflow: hidden;
            border-radius: 15px;

            background-color: #ddd;
            color: #333;

            padding: 15px;

            img {
                width: 110%;
                margin: -15px;
            }
        }

        overflow: hidden;

        transition: 1000ms;
        &:not(.selected) {
            height: 0 !important;
            opacity: 0;
        }
    }

    .submit {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        margin-top: 15px;

        input { width: 100%; }
    }
}