body {
  overflow-y: scroll;
  overflow-x: hidden;
}

.App {
  // position: fixed;
  // top: 0;
  // left: 0;
  // right: 0;
  // bottom: 0;

  overflow-y: scroll;
  overflow-x: hidden;
  font-family: Helvetica Neue, Arial, sans-serif;

  header {
    padding: 5px 10px;

    display: flex;
    justify-content: space-between;
    align-items: start;
  }

  .btn {
    border: 0;
    padding: 13px 16px;
    outline: none;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    border-radius: 4px;
    background: linear-gradient(to bottom, #bdbdbd, #838383);
    cursor: pointer;

    &.btn-blue {
      background: linear-gradient(to bottom, #477DBC, #3A6598);
    }

    &.btn-green {
      background: linear-gradient(to bottom, #81BC58, #6A9B48);
    }

    &:disabled {
      opacity: 50%;
    }
  }

  .input {
    padding: 11px 16px;
    margin: 8px 0;
    box-sizing: border-box;
    border: 2px #477dbc solid;
    border-radius: 5px;
    font-size: 16px;
  }
}
