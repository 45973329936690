.result {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    color: #477DBC;

    &.hidden { display: none; }

    .background {
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
    }

    .container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -100%);
        text-align: center;
        width: 80%;
        max-width: 400px;

        p {
            margin: 0;

            &#title, &#answer {
                font-size: 18px;
                margin: 0 0 5px;
                font-weight: bold;

                &#answer { margin: 0; font-style: italic; }
            }
        }

        img {
            width: 50%;
            margin-bottom: 5px;
        }
    }
}